<template>
  <div class="areaMap-street">
    <a-card :title="`${regionName}源头计量街道信息`" style="width: 100%;background:#0f3360;">
        <a-table :columns="columns" :data-source="data" rowKey="regionName">
            <span slot="kitchenWastePercent" slot-scope="text,record">{{record.kitchenWastePercent}}%</span>
            <span slot="otherPercent" slot-scope="text,record">{{record.otherPercent}}%</span>
            <span slot="centerName" slot-scope="text, record"><a>{{record.centerName}}</a></span>
            <template slot="action">
                <a>查看</a>
            </template>
        </a-table>
    </a-card>
  </div>
</template>

<script>
const key = 'loading';
import { queryMeteringDetailsApi } from '@/api/map'
export default {
    props: {
        regionName: {
            type: String,
            default: ''
        }
    },
    data() {
        return{
            columns: [
                {
                    title: '街道',
                    dataIndex: 'regionName',
                    key: 'regionName',
                    ellipsis: true,
                    scopedSlots: { customRender: 'regionName' },
                },
                {
                    title: '小区数量',
                    dataIndex: 'countByCenter',
                    key: 'countByCenter'
                }, {
                    title: '厨余分出率',
                    dataIndex: 'kitchenWastePercent',
                    key: 'kitchenWastePercent',
                    scopedSlots: { customRender: 'kitchenWastePercent' },
                }, {
                    title: '其他垃圾分出率',
                    dataIndex: 'otherPercent',
                    key: 'otherPercent',
                    scopedSlots: { customRender: 'otherPercent' },
                },
                {
                    title: '接入数据小区',
                    dataIndex: 'hasDataCenter',
                    key: 'hasDataCenter'
                },
            ],
            data: [
                {
                    streetName: '东南街道',
                    center: 12
                }
            ],
            params: {}
        }
    },
    mounted() {
    },
    methods: {
        queryMeteringDetails() {
            this.$message.loading({ content: '加载中...', key })
            queryMeteringDetailsApi({...this.params}, this.$route.query.moduleCode).then(res => {
                this.data = res.result
                this.$message.success({ content: '加载完成!', key, duration: 2 });
            })
        },
        changeTime(data) {
            this.params = data
            this.queryMeteringDetails()
        }
    }
}
</script>

<style lang="scss">
.areaMap-street{
   .ant-table-thead > tr > th, .ant-table-tbody > tr > td{
       padding: 5px !important;
   }
   .ant-table-thead > tr > th{
       background: #0f3360;
       color: white;
   }
   .ant-table-column-title{
       white-space: nowrap;
   }
   .ant-card-head{
       color: white;
       border-color: #0f3360 !important;
   }
   .ant-table-body{
       color: white;
   }
   .ant-card-bordered {
        border: 1px solid #0f3360;
    }
}
</style>