<template>
  <div class="areaMap">
    <a-card :title="`${regionName}源头计量采集`" style="width: 100%;background:#0f3360;">
        <a-row :gutter="20" >
            <a-col :span="12" style="display: flex;flex-direction: row;justify-content: center;align-items: center;;position:relative;">
                <img
                    alt="example"
                    :src="images[0]"
                    style="width: 44%;"
                />
                <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
                    <h3 style="color: white;">小区数量(个)</h3>
                    <p style="font-size: 20px;">{{centerTotal}}</p>
                </div>
            </a-col>
             <a-button type="default" :icon="ishide ? 'caret-right' : 'caret-left'"  style="position:absolute;right: -11%;top: -82%;" @click="hide" fill="#fff"/>
            <a-col :span="12" style="display: flex;flex-direction: row;justify-content: center;align-items: center;">
                <img
                    alt="example"
                    :src="images[1]"
                    style="width: 44%;"
                />
                <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
                    <h3 style="color: white;">清洁屋(个)</h3>
                    <p style="font-size: 20px;">{{houseTotal}}</p>
                </div>
            </a-col>
        </a-row>
    </a-card>
  </div>
</template>

<script>
import { queryMeteringByCodeApi } from '@/api/map'
const images = [
    'https://zhongshi-garbage.oss-cn-shanghai.aliyuncs.com/screen/ec063632702235fc59ab371d92a4302.png',
    'https://zhongshi-garbage.oss-cn-shanghai.aliyuncs.com/screen/b4a86d443a4046c98e30a8c8132dc2f.png',
    'https://zhongshi-garbage.oss-cn-shanghai.aliyuncs.com/screen/0d01d9ed5d8da2cca4ba15f8a881638.png'
]
export default {
    props: {
        ishide: {
            type: Boolean,
            default: false
        },
        regionName: {
            type: String,
            default: ''
        }
    },
    data() {
        return{
            images,
            centerTotal: '',
            houseTotal: ''
        }
    },
    mounted() {
        this.queryMeteringByCode()
    },
    methods: {
        hide() {
            this.$emit('hide', !this.ishide)
        },
        queryMeteringByCode() {
            queryMeteringByCodeApi(this.$route.query.moduleCode).then(res => {
                this.centerTotal = res.result.centerTotal
                this.houseTotal = res.result.houseTotal
            })
        }
    }
}
</script>

<style lang="scss">
.areaMap{
    .ant-card-bordered {
        border-color: #132230 !important;
    }
    .ant-card-head{
        color: white;
        border-color: #132230;
    }
    .ant-card-body{
        color: white;
    }
}
</style>