<template>
    <div style="height: 100%;overflow:hidden;">
        <div
                :id="mapId"
                class="m-map"
                style="width:100%;height:100%"/>
        <a-row :class="[isHideLeft ? 'hide-left-panel-before hide-left-panel' : 'hide-left-panel-before']">
            <a-col :span="24">
                <card-comprehensive :ishide="isHideLeft" :regionName="regionName" style="width: 80%;"
                                    @hide="changeHideLeft"/>
            </a-col>
            <a-col :span="24">
                <card-street v-if="level < 3" ref="cardStreet" :regionName="regionName" style="margin-top: 20px;"/>
            </a-col>
        </a-row>
        <a-row :class="[isHideRight ? 'hide-right-panel-before hide-right-panel' : 'hide-right-panel-before']">
            <a-col :span="24" style="margin-bottom: 3%;">
                <a-row :gutter="20">
                    <a-col :span="6">
                        <a-select :default-value="currentTime" style="width: 120px" @change="handleChange">
                            <a-select-option v-for="(item, index) in time" :key="index" :value="item.key">
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-col>
                    <a-col :span="18">
                        <a-range-picker v-if="currentTime == 'day'" :default-value="dayDate"
                                        placeholder="选择日期" @change="onChange('day', $event)"/>
                        <a-month-picker v-if="currentTime == 'month'" :default-value="monthDate"
                                        placeholder="选择月份" @change="onChange('month', $event)"/>
                        <a-date-picker v-if="currentTime == 'year'" v-model="year" format='YYYY'
                                       mode="year" placeholder="选择年份" @panelChange="panelChangeOne"></a-date-picker>
                    </a-col>
                </a-row>
            </a-col>
            <a-col :span="24">
                <card-type ref="cardType" :ishide="isHideRight" :regionName="regionName" style="margin-bottom: 2%;"
                           @hide="changeHideRight"/>
            </a-col>
            <a-col :span="24">
                <card-chart ref="cardChart" :regionName="regionName"/>
            </a-col>
        </a-row>
        <center-modal ref="centerModal"/>
    </div>
</template>
<script>
import {mapState} from 'vuex'
import centerModal from './component/center.vue'
import cardChart from '@/components/areaMapView/component/cardChart'
import cardType from '@/components/areaMapView/component/cardType'
import cardStreet from './component/cardStreet'
import cardComprehensive from './component/cardComprehensive'
import {queryMeteringCentersApi} from '@/api/map'
import moment from 'moment'

const url = 'https://webapi.amap.com/maps?v=2.0&key=2b204e0f00c23d4a9c09a4e35ba88150&callback=onMapLoad&plugin=AMap.MarkerCluster,AMap.ToolBar,AMap.Scale,AMap.HeatMap,AMap.Size,AMap.Pixel'
export default {
    components: {cardComprehensive, cardStreet, cardType, cardChart, centerModal},
    data() {
        return {
            heatMap: null,
            permissions: '',
            regionName: '',
            marker: [],
            visible: false,
            isHideRight: false,
            isHideLeft: false,
            mapId: 'mapId', // 地图id，多次调用该地图组件时，id必须动态生成
            map: null,
            centerListData: [],
            markers: [],
            cluster: [],
            distCluster: null,
            time: [{key: 'day', name: '日'}, {key: 'month', name: '月'}, {key: 'year', name: '年'}],
            currentTime: 'month',
            year: moment(),
            monthDate: moment(),
            dayDate: [moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'), moment().startOf('month').format("YYYY-MM-DD")]
        }
    },
    watch: {
        // 经纬度
        point: function (val, old) {
            this.map.setCenter(val)
            this.marker.setPosition(val)
        }
    },
    mounted() {
        this.$getCurrentModuleInfo(this.$route.query.moduleCode).then(res => {
            this.regionName = res.regionName
            this.permissions = res.permissions
            this.createMap()
        })
    },
    created() {
    },
    methods: {
        changeHideLeft(hide) {
            this.isHideLeft = hide
        },
        changeHideRight(hide) {
            this.isHideRight = hide
        },
        createMap() {
            const jsapi = document.createElement('script')
            jsapi.charset = 'utf-8'
            jsapi.src = url
            document.head.appendChild(jsapi)
            const _this = this
            this.mapId = `map${Math.random().toString().slice(4, 6)}`
            window.onMapLoad = function () {
                const map = new window.AMap.Map(_this.mapId, {
                    // resizeEnable: true, // resizeEnable: true, //是否监控地图容器尺寸变化
                    // rotateEnable:false,
                    // pitchEnable:false,
                    // zoom: 5,
                    pitch: 55,
                    viewMode: '3D',
                    // expandZoomRange:true,
                    // zooms:[3,20],
                    // mapStyle: "amap://styles/5371b1e9d7f46fb46148284fe076d52d"
                    mapStyle: "amap://styles/darkblue"
                })
                _this.map = map
                _this.map.addControl(new AMap.ToolBar());
                _this.map.addControl(new AMap.Scale());
                let adcode = _this.permissions.substring(0, 6)
                // let adcode = 320500
                /**
                 *地图初始化，根据区域code ，320571标记
                 */
                window.AMapUI.load(['ui/geo/DistrictExplorer'], function (DistrictExplorer) {
                    _this.districtExplorers = new DistrictExplorer({//创建一个实例
                        map: _this.map,
                        eventSupport: true, //打开事件支持
                    });
                    _this.districtExplorers.loadAreaNode(adcode, function (error, areaNode) {

                        if (error) {
                            console.error(error);
                            return;
                        }
                        console.log(areaNode, 'areaNode')

                        //绘制载入的区划节点
                        renderAreaNode(_this.districtExplorers, areaNode);
                    });

                    function renderAreaNode(districtExplorer, areaNode) {
                        //清除已有的绘制内容
                        districtExplorer.clearFeaturePolygons();

                        //just some colors
                        var colors = ["#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477", "#66aa00"];

                        //绘制子级区划
                        districtExplorer.renderSubFeatures(areaNode, function (feature, i) {

                            var fillColor = colors[i % colors.length];
                            var strokeColor = colors[colors.length - 1 - i % colors.length];

                            return {
                                cursor: 'default',
                                bubble: true,
                                strokeColor: strokeColor, //线颜色
                                strokeOpacity: 1, //线透明度
                                strokeWeight: 1, //线宽
                                fillColor: fillColor, //填充色
                                fillOpacity: 0.35, //填充透明度
                            };
                        });

                        //绘制父级区划，仅用黑色描边
                        districtExplorer.renderParentFeature(areaNode, {
                            cursor: 'default',
                            bubble: true,
                            strokeColor: 'black', //线颜色
                            fillColor: "#0081ff",
                            strokeWeight: 4,
                            fillOpacity: 0.2
                        });
                        _this.map.setFitView(districtExplorer.getAllFeaturePolygons());
                    }
                });
                /**
                 * 获取小区坐标
                 */
                _this.renderMarker({time: 'month', startTime: this.monthDate})
                _this.map.setFitView(null, false, [100, 150, 10, 10]);
                if (_this.level < 3)
                    _this.$refs.cardStreet.changeTime({time: 'month', startTime: _this.monthDate})
                _this.$refs.cardChart.changeTime({time: 'month', startTime: _this.monthDate})
                _this.$refs.cardType.queryMeteringType({time: 'month', startTime: _this.monthDate})

            }
        },
        showModal(code) {
            this.$refs.centerModal.showModal(code)
        },
        onChange(type, e) {
            let params = {}
            if (type === 'day') {
                params = {
                    time: 'day',
                    startTime: e[0],
                    endTime: e[1]
                }
            } else if (type == 'month') {
                params = {
                    time: 'month',
                    startTime: e
                }
            }
            if (this.level < 3)
                this.$refs.cardStreet.changeTime(params)
            this.$refs.cardChart.changeTime(params)
            this.$refs.cardType.queryMeteringType(params)
            this.renderMarker(params)
        },
        handleChange(e) {
            this.currentTime = e
            let params = {}
            if (e == 'day') {
                params = {time: 'day', startTime: this.dayDate[0], endTime: this.dayDate[1]}
            } else if (e == 'month') {
                params = {time: 'month', startTime: this.monthDate}
            } else {
                params = {time: 'year', startTime: this.year}
            }
            if (this.level < 3)
                this.$refs.cardStreet.changeTime(params)
            this.$refs.cardChart.changeTime(params)
            this.$refs.cardType.queryMeteringType(params)
        },
        panelChangeOne(value) {
            this.year = value
            let params = {time: 'year', startTime: this.year}
            this.$refs.cardChart.changeTime(params)
            this.$refs.cardType.queryMeteringType(params)

        },
        renderMarker(params) {
            const _this = this
            queryMeteringCentersApi({...params}, _this.$route.query.moduleCode).then(res => {
                _this.centerListData = res.result
                _this.markers = []
                let heatMapData = []
                _this.centerListData.forEach(item => {
                    if (item.longitude !== null) {
                        _this.markers.push({
                            lnglat: [item.longitude, item.latitude],
                            name: item.centerName,
                            code: item.centerCode,
                            hasData: item.hasData
                        })
                        heatMapData.push({
                            lng: item.longitude,
                            lat: item.latitude,
                            count: 1
                        })
                    }
                })
                /**
                 *点聚合
                 */
                var count = _this.markers.length;
                var _renderClusterMarker = function (context) {
                    var factor = Math.pow(context.count / count, 1 / 18);
                    var div = document.createElement('div');
                    var Hue = 180 - factor * 180;
                    var bgColor = 'hsla(' + Hue + ',100%,40%,0.7)';
                    var fontColor = 'hsla(' + Hue + ',100%,90%,1)';
                    var borderColor = 'hsla(' + Hue + ',100%,40%,1)';
                    var shadowColor = 'hsla(' + Hue + ',100%,90%,1)';
                    div.style.backgroundColor = bgColor;
                    var size = Math.round(30 + Math.pow(context.count / count, 1 / 5) * 20);
                    div.style.width = div.style.height = size + 'px';
                    div.style.border = 'solid 1px ' + borderColor;
                    div.style.borderRadius = size / 2 + 'px';
                    // div.style.boxShadow = '0 0 5px ' + shadowColor;
                    div.innerHTML = context.count;
                    div.style.lineHeight = size + 'px';
                    div.style.color = fontColor;
                    div.style.fontSize = '14px';
                    div.style.textAlign = 'center';
                    context.marker.setOffset(new AMap.Pixel(-size / 2, -size / 2));
                    context.marker.setContent(div)
                };
                var _renderMarker = function (context) {
                    var content = '<div style="background-color: hsla(180, 100%, 50%, 0.3); height: 18px; width: 18px; border: 1px solid hsl(180, 100%, 40%); border-radius: 12px; box-shadow: hsl(180, 100%, 50%) 0px 0px 3px;"></div>';
                    var offset = new AMap.Pixel(-9, -9);
                    context.marker.setContent(content)
                    context.marker.setOffset(offset)
                }
                addCluster(2);
                var gridSize = 60

                function addCluster(tag) {
                    if (tag == 2) {//完全自定义
                        _this.cluster = new AMap.MarkerCluster(_this.map, _this.markers, {
                            gridSize: gridSize, // 设置网格像素大小
                            zoomOnClick: true,
                            renderClusterMarker: _renderClusterMarker, // 自定义聚合点样式
                            renderMarker: _renderMarker, // 自定义非聚合点样式
                        });
                    } else if (tag == 1) {//自定义图标
                        var sts = [{
                            url: "//a.amap.com/jsapi_demos/static/images/blue.png",
                            size: new AMap.Size(32, 32),
                            offset: new AMap.Pixel(-16, -16)
                        }, {
                            url: "//a.amap.com/jsapi_demos/static/images/green.png",
                            size: new AMap.Size(32, 32),
                            offset: new AMap.Pixel(-16, -16)
                        }, {
                            url: "//a.amap.com/jsapi_demos/static/images/orange.png",
                            size: new AMap.Size(36, 36),
                            offset: new AMap.Pixel(-18, -18)
                        }, {
                            url: "//a.amap.com/jsapi_demos/static/images/red.png",
                            size: new AMap.Size(48, 48),
                            offset: new AMap.Pixel(-24, -24)
                        }, {
                            url: "//a.amap.com/jsapi_demos/static/images/darkRed.png",
                            size: new AMap.Size(48, 48),
                            offset: new AMap.Pixel(-24, -24)
                        }];
                        _this.cluster = new AMap.MarkerCluster(_this.map, _this.markers, {
                            styles: sts,
                            gridSize: gridSize
                        });
                    } else {//默认样式
                        _this.cluster = new AMap.MarkerCluster(_this.map, _this.markers, {gridSize: gridSize});
                    }
                }

                /**
                 *为每个坐标添加marker
                 */
                var icon = {};
                let tmpLabelsData = {
                    name: '',
                    position: [120.852892, 31.287836],
                    zooms: [10, 20],
                    opacity: 1,
                    zIndex: 10,
                    fold: true,
                    icon,
                    text: {}
                }
                var LabelsData = []
                _this.centerListData.forEach(item => {
                    if (item.longitude !== null) {
                        tmpLabelsData.name = item.centerCode
                        tmpLabelsData.position = [item.longitude, item.latitude]
                        tmpLabelsData.icon = {}
                        if (item.hasData == false) {
                            tmpLabelsData.icon = {
                                type: 'image',
                                // 图片 url
                                image: 'https://zhongshi-garbage.oss-cn-shanghai.aliyuncs.com/screen/cde5440f0e3e06efa97e011a7921b56.png',
                                // 图片尺寸
                                size: [70, 60],
                                // 图片相对 position 的锚点，默认为 bottom-center
                                anchor: 'center',
                                code: item.centerCode
                            }
                        } else {
                            tmpLabelsData.icon = {
                                type: 'image',
                                // 图片 url
                                image: 'https://zhongshi-garbage.oss-cn-shanghai.aliyuncs.com/screen/69c18d76412fa1e78367459443cd0d5.png',
                                // 图片尺寸
                                size: [70, 60],
                                // 图片相对 position 的锚点，默认为 bottom-center
                                anchor: 'center',
                                code: item.centerCode
                            }
                        }
                        tmpLabelsData.text = {
                            // 要展示的文字内容
                            // content: item.centerName,
                            // 文字方向，有 icon 时为围绕文字的方向，没有 icon 时，则为相对 position 的位置
                            direction: 'right',
                            // 在 direction 基础上的偏移量
                            offset: [-20, -1],
                            // 文字样式
                            style: {
                                // 字体大小
                                fontSize: 16,
                                // 字体颜色
                                fillColor: '#22886f',
                                //
                                // strokeColor: '#fff',
                                // strokeWidth: 2,
                                fold: true,
                                padding: '2, 5',
                            }
                        }
                        LabelsData.push({...tmpLabelsData})
                    }
                })
                var allowCollision = false;
                var layer = new AMap.LabelsLayer({
                    zooms: [3, 20],
                    zIndex: 1000,
                    allowCollision,
                });
                layer.remove(_this.marker)
                _this.marker = []
                layer.add(_this.marker);
                // 图层添加到地图
                _this.map.add(layer);
                // 初始化 labelMarker
                for (var i = 0; i < LabelsData.length; i++) {
                    var curData = LabelsData[i];
                    curData.extData = {
                        code: LabelsData[i].name
                    };
                    var labelMarker = new AMap.LabelMarker(curData);
                    _this.marker.push(labelMarker);
                    // 给marker绑定事件
                    labelMarker.on('click', function (e) {
                        _this.$refs.centerModal.showModal(e.data.opts.icon.code)
                    });
                }
                // 将 marker 添加到图层
                layer.add(_this.marker);
                //绘制热力图
                _this.heatMap = new AMap.HeatMap(_this.map, {
                    radius: 25,
                    opacity: [0, 0.8]
                })
                _this.heatMap.setDataSet({
                    data: heatMapData,
                    max: 100
                })
            })
            _this.map.setFitView(null, false, [100, 150, 10, 10]);

        }
    },
    computed: mapState({
        level: state => state.user.module.level
    })
}
</script>

<style lang="scss">
.hide-left-panel {
  left: -20% !important;
}

.hide-left-panel-before {
  transition: all .3s ease;
  position: absolute;
  left: 1%;
  top: 3%;
  width: 32%;
}

.hide-right-panel {
  right: -20% !important;
}

.hide-right-panel-before {
  transition: all .3s ease;
  position: absolute;
  right: 1%;
  top: 3%;
  width: 30%;
}
</style>