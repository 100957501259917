<template>
  <div class="areaMap">
    <a-card style="width: 100%;margin-bottom:10px;background:#0f3360;max-height: 300px;">
        <!-- <a-carousel  dotPosition="bottom" > -->
            <div id="cityContainer"></div>
        <!-- </a-carousel> -->
    </a-card>
    <a-card style="width: 100%;background:#0f3360;max-height: 300px;">
        <!-- <a-carousel  dotPosition="bottom" > -->
            <div id="streetContainer" v-if="level < 3"></div>
        <!-- </a-carousel> -->
    </a-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { queryMeteringPartApi, queryMeteringStreetApi } from '@/api/map'
import { DualAxes, Line } from '@antv/g2plot';
export default {
    data() {
        return{
            regionName: '',
            current: 0,
            cityChart: '',
            streetChart: ''
        }
    },
    methods: {
        changeTime(params) {
            this.drawCityChart(params)
            this.drawStreetChart(params)
        },
        drawCityChart(params) {
            queryMeteringPartApi({...params}, this.$route.query.moduleCode).then(res => {
                if (this.cityChart) {
                    this.cityChart.update({
                        data: res.result,
                    })
                    return
                }
                this.cityChart = new Line('cityContainer', {
                    autoFit: true,
                    data: res.result,
                    xField: 'day',
                    yField: 'weight',
                    smooth: true,
                    height: 230,
                    yAxis: {
                        label: {
                        // 数值格式化为千分位
                        formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
                        },
                    },
                    legend: {
                        itemName: {
                        style: {
                            fill: '#fff',
                        },
                        },
                    },
                    seriesField: 'trash',
                    color: ({ trash }) => {
                        return trash === '厨余垃圾' ? '#707070' : trash === '其他垃圾' ? '#0081ff' :  trash === '可回收物' ? '#39b54a' : '#d81e06';
                    },
                    // lineStyle: ({ trash }) => {
                    //     if (trash === '厨余垃圾') {
                    //     return {
                    //         lineDash: [4, 4],
                    //         opacity: 1,
                    //     };
                    //     }
                    //     return {
                    //     opacity: 0.5,
                    //     };
                    // },
                });

                this.cityChart.render();
            })
        },
        drawStreetChart(params) {
            queryMeteringStreetApi({...params}, this.$route.query.moduleCode).then(res => {
                if (this.streetChart) {
                    this.streetChart.update({
                        data: [res.result.typeList, res.result.kitchenWasteList],
                    })
                    return
                }
                const streetData = res.result.typeList
                const kitchenWasteList = res.result.kitchenWasteList
                this.streetChart = new DualAxes('streetContainer', {
                    data: [streetData, kitchenWasteList],
                    xField: 'regionName',
                    yField: ['weight', 'kitchenWastePercent'],
                    autoFit: true,
                    height: 230,
                      meta: {
                        kitchenWastePercent: {
                            alias: '厨余分出率',
                            formatter: (val) => {
                                return `${val}%`
                            }
                        },
                        weight: {
                            formatter: (val) => {
                                val = val.toFixed(2)
                                return `${val}kg`
                            }
                        }
                    },
                    geometryOptions: [
                        {
                        geometry: 'column',
                        isStack: true,
                        seriesField: 'typeName',
                        columnWidthRatio: 0.4,
                        color: ({ typeName }) => {
                            return typeName === '厨余垃圾' ? '#707070' : typeName === '其他垃圾' ? '#0081ff' :  typeName === '可回收物' ? '#39b54a' : '#d81e06';
                        },
                        label: {
                            style: {
                                fill: 'red',
                                opacity: 0.6,
                                fontSize:0
                            },
                        },
                        },
                        {
                            geometry: 'line',
                            smooth: true,
                            color: '#5B8FF9',
                        },
                    ],
                    legend: {
                        marker: {
                        symbol: 'circle',
                        style: {
                            lineWidth: 2,
                            r: 3,
                        },
                        },
                        layout: 'horizontal',
                        position: 'top-left',
                        itemName: {
                        formatter: (val) => {
                            if(val == 'kitchenWastePercent') return '厨余分出率'
                            else {
                                return `${val}`
                            } 
                        },
                        style: {
                            fill: '#fff',
                        },
                        },
                    },
                    interactions: [
                        {
                        type: 'element-highlight',
                        },
                        {
                        type: 'active-region',
                        },
                    ],
                    animation: true,
                    theme: 'custom-theme',
                });
                this.streetChart.render();
            })
        }
    },
    computed: mapState({
        level: state => state.user.module.level
    })
}
</script>

<style lang="scss">
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}
.ant-carousel .slick-dots-bottom{
    bottom: -15px;
}
.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
.ant-carousel .slick-dots li button{
    background: black;
}
.ant-carousel .slick-dots li.slick-active button{
    background: red;
}
        .ant-card-bordered {
        border-color: #132230 !important;
    }
    .ant-card-head{
        color: white;
        border-color: #132230;
    }
    .ant-card-body{
        color: white;
    }
</style>