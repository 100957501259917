<template>
  <div class="areaMap">
    <a-card :title="`${regionName}四分类采集信息`" style="" class="city-card-type">
        <a-row :gutter="20" >
            <a-col :span="12" style="" class="city-type-col" v-for="(item, index) in result" :key="index">
                <img
                    alt="example"
                    :src="images[index]"
                    style="width: 26%;"
                />
                <div style="display: flex;flex-direction: column;justify-content: space-around;align-items: center;">
                    <p>
                        <span style="font-size: 17px;"> {{item.weight | formatWeight}}</span>
                        <br/>同比： {{item.chainComparison}}%
                        <br/> 环比： {{item.yearToYear}}%
                    </p>
                </div>
            </a-col>
        </a-row>
    </a-card>
     <a-button type="default" :icon="ishide ? 'caret-left' : 'caret-right'"  style="position:absolute;left: -7%;top: 2%;" @click="hide"/>
  </div>
</template>

<script>
import { queryMeteringTypeApi } from '@/api/map'
const images = [
    'https://yihaoclub.oss-cn-shanghai.aliyuncs.com/image/cook.svg',
    'https://yihaoclub.oss-cn-shanghai.aliyuncs.com/image/other.svg',
    'https://yihaoclub.oss-cn-shanghai.aliyuncs.com/image/recycle.svg',
    'https://yihaoclub.oss-cn-shanghai.aliyuncs.com/image/danger.svg'
]
export default {
    props: {
        ishide: {
            type: Boolean,
            default: false
        },
        regionName: {
            type: String,
            default: ''
        }
    },
    filters: {
        formatWeight(val) {
            if(val > 100000) {
                return (val / 1000).toFixed(2) + 'T'
            }
            return val.toFixed(2) + 'KG'
        }
    },
    data() {
        return{
            images,
            result: ''
        }
    },
    mounted() {
    },
    methods: {
        hide(){
            this.$emit('hide', !this.ishide)
        },
        queryMeteringType(parameters) {
            queryMeteringTypeApi({...parameters}, this.$route.query.moduleCode).then(res => {
                this.result = res.result
            })
        }
    }
}
</script>

<style lang="scss">
.areaMap{
    .city-card-type{
        width: 100%;
        position:relative;
        background:#0f3360;
        .city-type-col{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
        }
    }
    .ant-card-bordered {
        border-color: #132230 !important;
    }
    .ant-card-head{
        color: white;
        border-color: #132230;
    }
    .ant-card-body{
        color: white;
    }
    h3{
        color:#fff;
    }
}
</style>